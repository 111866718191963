<template>
  <b-modal
    ref="round-modal"
    no-close-on-backdrop
    hide-footer
    title="เพิ่มงวดหวย"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="วันที่"
      >
        <flat-pickr
          id="round-date"
          :config="{enableTime: false, dateFormat: 'Y-m-d', maxDate: tomorrow}"
          v-model="date"
          class="form-control"
        />
      </b-form-group>

      <div class="pt-3">
        <b-button type="submit" variant="success" block pill :disabled="isProcess">{{isProcess ? 'กำลังเพิ่มงวด...' : 'เพิ่มงวด'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import moment from '@/helpers/moment'
import LottoService from '@/services/lottoService'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import Swal from 'sweetalert2'

export default {
  name: 'RoundModal',
  components: {
    flatPickr
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      needReload: false,
      isProcess: false,
      tomorrow: moment().add(1, 'd').format("YYYY-MM-DD")
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.date = moment().format("YYYY-MM-DD")
    },
    showModal() {
      this.$refs['round-modal'].show()
    },
    hideModal() {
      this.$refs['round-modal'].hide()
    },
    handleSubmit() {
      this.isProcess = true
      LottoService.createRound(this.date)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()

          this.$notify({
            type: 'success',
            text: 'เพิ่มงวดหวยแล้ว!'
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'สร้างงวดไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
