import AuthService from '@/services/authService'
import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isDataLoading: false,
  userProfile: {
    fullname: '',
    username: ''
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateLoadingStatus(state, isLoading) {
    state.isDataLoading = isLoading
  },
  updateProfile (state, profile) {
    state.userProfile = profile
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile()
    .then((profile) => {
      commit('updateProfile', profile)
    })
    .catch((e) => {
      console.log(e)
      AuthService.logout()
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
