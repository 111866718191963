import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import 'regenerator-runtime/runtime'
import moment from '@/helpers/moment'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Validation
import { ValidationProvider } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.component('loading', Loading)

// Notifications
import Notifications from 'vue-notification'
Vue.use(Notifications)

// Firebase Authentication
import firebase from 'firebase/app'
import 'firebase/auth'
const configOptions = {
  apiKey: "AIzaSyAzaBiQCy1_ucChSRNb5r4AQCayzJH873o",
  authDomain: "lotto-388408.firebaseapp.com",
  projectId: "lotto-388408",
  storageBucket: "lotto-388408.appspot.com",
  messagingSenderId: "472810097539",
  appId: "1:472810097539:web:37440d4c766dc6818784b6",
  measurementId: "G-LKFLHZF5BP"
};
firebase.initializeApp(configOptions)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
