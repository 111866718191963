<template>
  <div class="c-app">
    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader/>
      <div class="c-body position-relative">
        <main class="c-main">
          <transition name="fade" mode="out-in">
            <router-view :key="$route.path"></router-view>
          </transition>
        </main>

        <loading
          :active="isLoading"
          :can-cancel="false"
          :is-full-page="false"
          background-color="#EBEDEF"
          :height="30"
          :width="30"
          color="#007bff"
        />
      </div>
      <!--<TheFooter/>-->
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  computed: {
    isLoading() {
      return this.$store.state.isDataLoading
    }
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
