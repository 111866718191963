import axios from 'axios'
import auth from "./authService"
import store from '@/store'

axios.interceptors.request.use(async function(config) {
  const token = await auth.getToken()
  config.headers.Authorization = `Bearer ${token}`
  config.withCredentials = true
  return config;
}, function(error) {
  return Promise.reject(error.response?.data);
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error?.response?.status === 401)
      return auth.logout()

    return Promise.reject(error?.response?.data);
  }
);

export default axios
